.terms {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  background: linear-gradient(
    45deg,
    rgba(246, 221, 252, 1) 0%,
    rgba(246, 221, 252, 0.3) 10%,
    rgba(246, 221, 252, 0) 47%,
    rgba(246, 221, 252, 0) 100%
  );
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  min-height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between;
  max-height: 24px;
}

.logo {
  width: 124px;
  height: 24px;
}

.loginText {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.02em;
  cursor: pointer;
}

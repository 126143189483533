.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 12px;
    position:fixed;
    background-color: #f2e5fc; 
    background-image:   linear-gradient(135deg, rgba(166,172,252,1) 0%, rgba(255,255,255,0.54) 46%, rgba(255,255,255,0) 100%);
    width: 50%;
    height: 150px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.modal-btn{
    display: flex;
    justify-content: center;
}
.btn{
    width: 40%;
}
.display-block {
    display: block;
}

.display-none {
    display: none;
}

.error{
    margin-top: 30px;
    text-align: center;
    color: red;
}

.success{
    margin-top: 30px;
    text-align: center;
    color: green;
}
.wizard-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    padding: 80px
}

.header {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    max-height:24px
}

.progressBar {
    width: 80%;
    display: flex;
    margin-top: 10px;
    align-content: center;
    justify-content: center;
    max-height:24px
}

.icon{
    width: 24px;
    height: 24px;
}

.logo{
    width: 124px;
    height: 24px;
}



@media screen and (max-width: 700px) {
    .wizard-container{
        padding: 12px;
    }
    .progressBar {
        width: 60%;
        margin-top: 42px;
    }

    .autocomplete-field{
        width: 90%;
    }

    .wizard-message{
        width: 90%;
    }
}
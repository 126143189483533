.org-container {
  display: flex;
  flex: 1;
  flex-direction: row !important;
  min-height: 100vh;
}

.org-dashboard {
  display: flex;
  flex-direction: column;
  flex: 75% !important;
  padding-top: 80px;
  padding-left: 80px;
  padding-right: 80px;
  height: fit-content;
}

.org-header {
  display: flex;
  flex: 100%;
  flex-direction: row !important;
  max-height: 24px;
}

.nameContainer {
  display: flex;
  flex: 100%;
  flex-direction: column;
}
.name {
  display: flex;
  flex: 30%;
  max-width: 30%;
  flex-direction: row !important;
  max-height: 100px;
}

.ico {
  width: 24px;
  height: 24px;
}

.org-logo {
  width: 124px;
  height: 24px;
}

.contentSaveButton {
  width: 100%;
  top: -80px;
  right: -15px;
}

.errorMsg {
  justify-content: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: center;
  color: red;
  width: 100%;
}
.loginMsg {
  justify-content: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: center;
  width: 100%;
}
.org-btn {
  width: 200px;
  align-self: left;
}

.stripeContainer {
  justify-content: center;
  flex-direction: column;
}

.org-labels {
  visibility: visible;
  display: flex;
}
.mobile-label {
  visibility: hidden;
  display: none;
}

.label {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #0c0030;
  opacity: 0.5;
}

.select-non-editable {
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media (max-width: 800px) {
  .org-dashboard {
    height: fit-content;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 50px;
  }
}

@media (max-width: 700px) {
  .org-dashboard {
    height: fit-content;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 30px;
  }
  .org-labels {
    visibility: hidden;
    display: none;
  }
  .mobile-label {
    visibility: visible;
    display: flex;
    width: 100%;
  }

  .mobile-label .col-form-label {
    padding-left: 0px;
  }
}

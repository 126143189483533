
.container{
    display: block;
    flex: 1;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}

.circle{
    position: absolute;
    top: calc(50% - 54px);
    left: calc(50% - 54px);
    transform: translate(-50%,-50%);
    width: 100px;
    height: 100px;
    border: 4px solid transparent;
    border-bottom: 4px solid #0C0030;
    border-top: 4px solid #0C1974;
    border-radius: 50%;
    animation: animate 2s linear infinite;
}

.circle:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    border: 4px solid transparent;
    border-bottom: 4px solid #7964d4;
    border-top: 4px solid #c38ce2;
    border-radius: 50%;
    animation: animate 2s linear infinite;
}

.circle:after{
    content: '';
    position: absolute;
    top: 24px;
    left: 24px;
    bottom: 24px;
    right: 24px;
    border: 4px solid transparent;
    border-bottom: 4px solid #3156d1;
    border-top: 4px solid #7282df;
    border-radius: 50%;
    animation: animate 4s linear infinite;
}

@keyframes animate{
    0%{transform: rotate(0deg);}
    100%{transform: rotate(360deg);}
}
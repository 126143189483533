.footer-container{  
    width: 100%;
    background-color: #787ec2;
    display: flex;
    bottom: 0;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px; 
}

.footer-content span{
    font-family: Circular Std;
    color: #0C0030;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
}
@font-face {
  font-family: 'Circular std';
  src: local('Circular std'), url(./fonts/circular-std-medium-500.ttf) format('truetype');
}

html {
  background: linear-gradient(
    135deg,
    rgba(166, 172, 252, 1) 0%,
    rgba(255, 255, 255, 0.54) 46%,
    rgba(255, 255, 255, 0) 100%
  );
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Circular std';
}

code {
  font-family: 'Circular std';
}

input {
  outline: none !important;
}

input::placeholder {
  color: #0c0030;
  opacity: 0.5;
}

.container {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.mobilePh {
  display: none;
  visibility: hidden;
}
.desktopPh {
  position: absolute;
  align-self: flex-end;
  visibility: visible;
  display: block;
  z-index: 0;
  min-width: 590px;
  max-width: 600px;
  margin-top: 160px;
}
.desktopPhTop {
  position: absolute;
  align-self: flex-end;
  visibility: visible;
  display: block;
  z-index: -1;
  min-width: 590px;
  max-width: 850px;
  top: 0;
  left: 10%;
}
.landing1 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(
    45deg,
    rgba(246, 221, 252, 1) 0%,
    rgba(246, 221, 252, 0.3) 10%,
    rgba(246, 221, 252, 0) 47%,
    rgba(246, 221, 252, 0) 100%
  );
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  min-height: 900px;
}
.landing2 {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  display: flex;
  flex-direction: row !important;
  background: linear-gradient(
    to right,
    rgba(246, 221, 252, 1) 0%,
    rgba(246, 221, 252, 0.3) 37%,
    rgba(246, 221, 252, 0) 47%,
    rgba(246, 221, 252, 0) 100%
  );
  flex: 1;
  z-index: 1;
}
.landing3 {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  /* background:  linear-gradient(135deg, rgba(246,221,252,0) 0%, rgba(187,185,250,0) 72%, rgba(180,181,250,0.3) 81%, rgba(164,171,249,1) 100%); */
  flex: 1;
}

.landing4 {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: linear-gradient(
    to right,
    rgba(246, 221, 252, 1) 0%,
    rgba(246, 221, 252, 0.3) 37%,
    rgba(246, 221, 252, 0) 47%,
    rgba(246, 221, 252, 0) 100%
  );
  flex: 1;
}

.line {
  border-bottom: 2px solid #0c0030;
}
.features-left {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.features-left span {
  font-family: Circular Std;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}

.features-left img {
  display: flex;
  width: 300px;
  align-self: center;
}

.features-right {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between;
  max-height: 24px;
}

.logo {
  width: 124px;
  height: 24px;
}

.loginText {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.02em;
  cursor: pointer;
}

.get-container .loginText {
  margin-top: 48px;
  margin-bottom: 48px;
}

.title {
  font-family: Circular Std;
  max-width: 50%;
  font-size: 120px !important;
  line-height: 104px !important;
  letter-spacing: -0.04em !important;
  text-transform: uppercase !important;
}

.title2 {
  font-family: Circular Std;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.04em !important;
  text-transform: uppercase !important;
}
.title3 {
  font-family: Circular Std;
  width: 100%;
  font-size: 120px !important;
  line-height: 104px !important;
  letter-spacing: -0.04em !important;
  text-align: center !important;
  text-transform: uppercase !important;
}

.landing1 .bodyText {
  z-index: 1;
  font-family: Circular Std;
  max-width: 57%;
  padding-left: 10%;
  text-indent: 15%;
  text-align: justify;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.05em;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: space-between;
}
.grid {
  width: 25%;
  padding: 20px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid img {
  max-width: 100%;
}
.get-container {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 48px;
}
.get-container-row {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}
.input-link {
  width: 60% !important;
  display: flex;
  justify-content: center;
}
.bodyBtn {
  background-color: #0c1974;
  width: 200px !important;
  height: 56px !important;
  filter: drop-shadow(0px 32px 64px rgba(12, 1, 254, 0.1)) drop-shadow(0px 2px 4px rgba(12, 1, 254, 0.1));
}

.donateText {
  visibility: hidden;
  display: none;
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.link-btn:hover {
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .line {
    border-bottom: 0px;
  }
  .mobilePh {
    align-self: center;
    display: block;
    visibility: visible;
    width: 100%;
    margin-top: -50px;
    margin-bottom: 20px;
    z-index: 1;
  }
  .desktopPh,
  .desktopPhTop {
    visibility: hidden;
    display: none;
  }
  .features-img {
    display: none !important;
  }
  .landing1 {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .title {
    position: absolute;
    font-size: 68px !important;
    line-height: 70px !important;
    max-height: 512px;
    margin-left: 0px;
    top: 50%;
    left: -7%;

    transform: rotate(-90deg);

    /* Safari */
    -webkit-transform: rotate(-90deg);

    /* Firefox */
    -moz-transform: rotate(-90deg);

    /* IE */
    -ms-transform: rotate(-90deg);

    /* Opera */
    -o-transform: rotate(-90deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .landing1 {
    padding: 20px 0 0 0;
  }
  .landing1 .header {
    padding: 0 10px;
  }
  .landing1 .bodyText {
    margin-top: -30%;
    padding-left: 0%;
    align-self: center;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: -0.02em;
  }
  .landing1 .bodyText .bodyText-text {
    margin-top: -120px;
  }
  .bodyBtn {
    width: 60% !important;
    min-width: 115px !important;
    height: 48px !important;
    align-self: center;
  }

  .landing2 {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column !important;
  }
  .features-left {
    width: 100%;
  }
  .features-left span {
    border-bottom: 2px solid #0c0030;
    font-size: 16px;
    line-height: 25px;
    height: 40px;
  }
  .features-left img {
    display: none;
    width: 300px;
    align-self: center;
  }
  .features-right {
    width: 100%;
  }

  .landing3 {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid {
    width: 30%;
    max-width: 30%;
  }

  .landing4 {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .title3 {
    font-size: 48px !important;
    line-height: 48px !important;
    letter-spacing: -0.05em !important;
    max-width: 70%;
    text-align: left !important;
  }
  .get-container {
    margin-top: 0;
  }
  .get-container input {
    visibility: hidden;
    display: none;
  }
  .get-container .loginText {
    visibility: hidden;
    display: none;
  }
  .get-container-row {
    flex-direction: column !important;
    justify-content: space-between;
  }
  .get-btn {
    margin: 0 auto 20px;
  }
  .donateText {
    visibility: visible;
    display: block;
    margin-bottom: 10px;
    padding-left: 3px;
    margin-bottom: 48px;
  }

  .link-btn {
    width: 100%;
  }

  .get-btn {
    width: 85% !important;
    height: 56px !important;
  }
}

@media screen and (min-height: 800px) {
  .title {
    top: 45%;
  }
}

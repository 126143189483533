.menu {
  display: flex;
  flex-direction: column;
  flex: 25%;
  padding-top: 80px;
  padding-right: 60px;
  padding-left: 60px;
  background: rgba(237, 234, 251, 1);
  background: linear-gradient(
    to bottom,
    rgba(228, 206, 249, 1) 0%,
    rgba(228, 206, 249, 1) 5%,
    rgba(219, 214, 250, 1) 100%
  );
  min-height: 100vh;
}

.initialsContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row !important;
  max-height: 32px;
}
.menu-logo {
  width: 124px;
  height: auto;
}
.initials {
  display: flex;
  flex-direction: column;
  border: 2px solid #0c0030;
  border-radius: 28px;
  width: 32px;
  height: 32px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  letter-spacing: -0.02em;
  color: #0c0030;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  flex: 0 !important;
}

.sectionContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  flex: 100%;
  border-bottom: 2px solid #aca8d3;
  max-height: 38px;
}

.sectionContainer.menuTitle {
  border-bottom: 0px;
}

.sectionTitle {
  display: flex;
  flex: 100%;
  max-height: 32px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: left;
  color: #0c0030;
}

.sectionLink {
  display: flex;
  flex: 100%;
  max-height: 32px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #0c0030;
  cursor: pointer;
}
.changeLink {
  width: 87px;
  height: 24px;
  padding-top: 4px;
  letter-spacing: -0.04em;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.5;
  cursor: pointer;
  border-bottom: 1px solid rgba(12, 0, 48, 0.5);
}

.errorContainer {
  display: flex;
  flex-direction: column;
  flex: 100%;
  max-height: 60px;
  min-height: 40px;
}
.sectionError {
  display: flex;
  flex: 100%;
  cursor: pointer;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
  color: red;
}

.stripeLink {
  max-height: 32px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: red;
  cursor: pointer;
  border-bottom: 2px solid red;
}

@media (max-width: 700px) {
  .menu {
    transition: 0.3s;
    display: none;
    padding-top: 30px;
    padding-right: 12px;
    padding-left: 12px;
    opacity: 0;
  }
  .over {
    opacity: 1;
    transition: 0.3s;
    display: flex;
    position: fixed;
    right: 0;
    z-index: 2;
  }

  .mobile-menu {
    position: absolute;
    flex: 0 0 0% !important;
    width: 32px;
    height: 32px;
    margin-top: 30px;
    margin-left: 90%;
    z-index: 1;
  }

  .mobile-menu-alt {
    position: absolute;
    flex: 0 0 0% !important;
    width: 32px;
    height: 32px;
    align-self: flex-end;
    z-index: 1;
  }

  .menu-icon {
    max-width: 32px;
    max-height: 32px;
  }
}

.table-container {
  display: flex;
  flex: 100%;
  flex-direction: column;
}

.table-title {
  display: flex;
  flex-direction: column;
  flex: 100%;
  max-height: 72px;
}

.table-titleText {
  display: flex;
  flex: 100%;
  max-height: 32px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: left;
  color: #0c0030;
}

.table-header {
  display: flex;
  flex: 100%;
  flex-direction: row !important;
  max-height: 40px;
  justify-content: space-between;
  border-bottom: 2px solid #aca8d3;
}

.table-headerText {
  max-height: 40px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  text-transform: capitalize;
  color: #0c0030;
}
.colLg {
  display: flex;
  flex: 30%;
  flex-direction: column;
  justify-content: center;
  max-width: 30%;
}
.colName {
  display: flex;
  flex: 30%;
  flex-direction: row !important;
  max-width: 30%;
  padding-left: 8px;
}
.colEmpty {
  display: flex;
  flex: 100%;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  text-align: center;
  text-transform: inherit;
  padding-left: 8px;
}
.colMd {
  display: flex;
  flex: 20%;
  flex-direction: column;
  justify-content: center;
  max-width: 20%;
}

.table-content {
  display: flex;
  flex: 100%;
  flex-direction: row !important;
  max-height: 80px;
  min-height: 80px;
  justify-content: space-between;
  cursor: pointer;
}

.table-contentText {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #0c0030;
  text-transform: capitalize;
}

.table-userInitials {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  margin-top: 16px;
  background: #0c0030;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  color: #edeafb;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .table-contentText {
    font-size: 12px;
    line-height: 18px;
  }

  .table-userInitials {
    font-size: 12px;
    line-height: 18px;
    width: 38px;
    height: 34px;
    margin-top: 22px;
  }
}

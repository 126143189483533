
.autocomplete-field{
    height: 62px;
    width: 480px;
}

.autocomplete-field > .input-field{
    height: 62px;
    border-bottom:1px solid #0C0030;
    border-bottom:1px solid rgba(12, 0, 48, .3);
}

.autocomplete-field > .input-field > .auto-complete{
    height: 62px;
    background: transparent;
    border-width: 0px;
    text-align: center;
    font-family: Circular Std;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.03em;
}

.autocomplete-field > .autocomplete-list.show{
    top: 62px;
    max-height: 400px !important;
    background: #efedfc;
    box-shadow: 0px 32px 64px rgba(12, 0, 48, 0.1), 0px 2px 4px rgba(12, 0, 48, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-bottom-left-radius: 12px ;
    border-bottom-right-radius: 12px ;
    font-family: Circular Std;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.03em;
}

.autocomplete-field .autocomplete-list > div{
    background: transparent !important;
    border-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
}

.autocomplete-field .autocomplete-list > div:hover{
    background: #0C1974 !important;
    color: #EDEAFB;
    border-bottom: 0px !important;
    font-weight: normal;
}



.block{
    display: block
}

.dashboard-container {
    display: flex;
    flex: 1;
    flex-direction: row !important;
    height: auto;
   
}

.dashboard{
    display: flex;
    flex-direction: column;
    flex: 75% !important;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px
}

.header {
    display: flex;
    flex: 100%;
    flex-direction: row;
    max-height: 24px
}

.nameContainer {
    display: flex;
    flex-direction: column;
    max-height: fit-content;
    flex-grow: 0 !important;
}

.dashboard-name {
    display: flex;
    flex: 50% !important;
    max-width: 50%;
    flex-direction: row;
    max-height: fit-content;
    flex-grow: 0 !important;
}

.icon{
    width: 24px;
    height: 24px;
}

.logo{
    width: 124px;
    height: 24px;
}
.errorMsg{
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px
}



@media (max-width: 1110px) {
    .name-container{
        max-width: 100% !important;
    }
    .name-container.dashboard-name h1{
        font-size: 25px !important;
        line-height: 25px !important;
    }
}

@media (max-width: 700px) {

    .dashboard{
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 30px;
    }
}
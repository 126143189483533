.accordion-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  border-bottom: 2px solid #0c0030;
}

.accordion-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #0c0030;
  cursor: pointer;
}
.accordion-index {
  width: 10%;
  text-align: left;
}
.accordion-title {
  width: 80%;
  text-align: left;
}
.accordion-icon {
  text-align: right;
}

.accordion-content {
  visibility: hidden;
  height: 0px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.05em;
  transition: 0.1s;
  opacity: 0;
}

.accordion-content span {
  width: 85.5%;
}

.active {
  visibility: visible;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  opacity: 1;
  transition: 0.2s;
}
.mobile-img {
  display: none;
}

@media screen and (max-width: 800px) {
  .accordion-content {
    flex-direction: column;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.05em;
  }
  .accordion-content span {
    margin-left: 25px;
  }
  .mobile-img {
    display: flex;
    width: 300px;
    align-self: center;
  }
}

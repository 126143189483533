.summary-container {
    display: flex;
    flex: 100%;
    flex-direction: column;
    max-height: 132px;
}

.summary-title{
    display: flex;
    flex-direction: column;
    flex: 100%;
    border-bottom: 2px solid #aca8d3;
    max-height: 35px;
}

.titleText{
    display: flex;
    flex: 100%;
    max-height: 32px;
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    text-align: left;
    color: #0C0030
}

.summary-header {
    display: flex;
    flex: 100%;
    flex-direction: row !important;
    max-height: 40px;
    justify-content: space-between
}

.headerText {
    display: flex;
    flex: 33%;
    flex-direction: column;
    max-width: 33%;
    max-height: 40px;
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #0C0030
}

.summary-content {
    display: flex;
    flex: 100%;
    flex-direction: row !important;
    max-height: 57px;
    justify-content: space-between
}

.contentText{
    display: flex;
    flex: 33%;
    flex-direction: column;
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 48px;
    text-align: left;
    color: #0C0030
}

.contentAlt{
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 48px;
    text-align: left;
    color: #0C0030
}

.contentAltSmall{
    display: flex;
    flex: 0 1 4%;
    flex-direction: column;
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    color: #0C0030
}


@media (max-width: 800px) {
    .summary-container{
        max-height: fit-content;
    }
    .summary-content{
        margin-top: 10px;
    }
    .summary-title{
        margin-top: 5px;
    }
    .summary-header{
        max-height: fit-content;
        
    }
    .contentAlt{
        font-size: 35px;
        line-height: 35px;
    }
    .contentText{
        font-size: 35px;
        line-height: 35px;
    }
    .headerText{
        font-size: 12px;
        line-height: 18px;
    }
}
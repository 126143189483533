

/*default version*/
@font-face {
	font-family: 'PoppinsRegular';
	src: url('fonts/Poppins/Poppins-Regular.ttf'); 
	src: 
		local('PoppinsRegular'),
		local('PoppinsRegular'),
		url('fonts/Poppins/Poppins-Regular.ttf') 
		format('opentype');
}

* {
	font-family: PoppinsRegular;
}

img {  
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
 }

html {
	user-select: none;
}

hr {
	width: 100%;
}

input[type=checkbox] {
	cursor: pointer;
}

.Toastify__toast--default {
	background: #2f353a !important;
	color: white !important;
}

.Toastify__progress-bar {
	height: 2px !important;
}

.Toastify__close-button {
	color: white !important;
}

.Toastify__toast-body {
	text-align: center !important;
}

.aside-menu {
	overflow-y: scroll;
}